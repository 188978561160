<template>
	<!-- 上传组件 -->
	<div class="upload_file flex flex-ac">
		<div class="update_file" :class="fileNumber > 1?'mr-20':''" :style="upStyle" v-for="(item,index) in fileList"
			:key="index" v-if="fileList.length != 0">
			<img :src="item" alt="" class="upload_img">
			<div class="upload_pos flex flex-ac flex-ja">
				<span class="upload-preview" @click="handlePictureCardPreview(item)">
					<i class="el-icon-zoom-in"></i>
				</span>
				<span class="upload-delete" @click="handleRemove(index)">
					<i class="el-icon-delete"></i>
				</span>
			</div>
		</div>
		<div class="update_button" :style="upStyle" v-if="(fileList.length  < fileNumber)">
			<div class="icon_pos flex flex-ac flex-jc">
				<i :class="iconName" :style="`font-size: ${iconSize}px;color:#c0c4cc;`"></i>
			</div>
			<input @change="changeImage($event)" accept="image/gif, image/jpeg, image/jpg, image/png" id="demoImg"
				multiple name="file" ref="avatarInput" type="file" :style="upStyle" />
		</div>
		<el-dialog :visible.sync="dialogVisible" append-to-body>
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>
<script>
	import axios from 'axios'
	// import { getQiNiuToken } from '@/network/api'
	import {
		randomString
	} from '../utils/util'
	export default {
		name: 'UploadFile',
		props: {
			upStyle: { //上传边框大小
				type: String,
				default: ''
			},
			iconSize: { //上传图标大小
				type: String,
				default: '14'
			},
			iconName: { //上传图标样式
				type: String,
				default: 'el-icon-upload'
			},
			fileNumber: { //文件数量
				type: [Number, String],
				default: 1
			},
			value: { //v-model
				type: [String, Array],
				default: null
			},
			upType: { //1 直接上传 2 保存上传
				type: String,
				default: '1'
			},
		},
		data() {
			return {
				//预览图片
				fileList: [],
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				domain: "https://upload-cn-east-2.qiniup.com", // 七牛云的上传地址（华南区）
				// domain: "https://upload.qiniup.com", // 七牛云的上传地址（华东区）
				domains: '', //批量上传
			}
		},
		watch: {
			value(newValue, oldValue) {
				if (this.upType == 1) {
					if (this.fileNumber == 1) {
						if (newValue) {
							this.fileList = [newValue]
						} else {
							this.fileList = []
						}
					} else {
						if (newValue.length > 0) {
							this.fileList = newValue
						} else {
							this.fileList = []
						}
					}
				}
			},
		},
		mounted() {
			console.log(this.value)
			if (this.value) {
				if (this.fileNumber == 1) {
					this.fileList = [this.value]
				} else {
					this.fileList = this.value
				}
			}
		},
		methods: {
			//上传图片
			changeImage(event) {
				let files = event.target.files
				if (files.length > this.fileNumber) {
					this.$message('一次最多上传' + this.fileNumber + '张发票!')
					return
				}
				if (this.upType == 1) {
					this.uploadToken(files) // 阿里云 上传
					// this.uploadToken2(files)  // 腾讯云 上传
				} else if (this.upType == 2) {
					let src = window.URL.createObjectURL(files[0]) //预览图片
					this.fileList.push(src)
					this.$emit('input', files[0])
				}
				//将图片文件转化成base64格式图片
				// var reader = new FileReader();
				// reader.onload = (e) => {
				//   //e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
				//   //使用axios的post方法上传即可
				// }
				// reader.readAsDataURL(event.target.files[0])
			},
			//删除图片
			handleRemove(index) {
				let fileList = this.fileList
				fileList.splice(index, 1)
				this.fileList = fileList
				if (this.fileNumber == 1) {
					this.$emit('input', '')
				} else {
					this.$emit('input', fileList)
				}
			},
			//放大图片
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file
				this.dialogVisible = true
			},
			//重新上传
			handleDownload(file) {
				this.changeImage()
			},
			//服务器 上传七牛云
			uploadFiles(files) {
				let formData = new FormData()
				for (let i = 0; i < files.length; i++) {
					formData.append("file", files[i])
				}
				this.$http.post('/upload/uploadFile', formData).then(({
					data: result
				}) => {
					console.log(result)
					if (this.fileNumber == 1) {
						this.fileList = result.data
						this.$emit('input', result.data[0])
					} else {
						let list = this.fileList.concat(result.data)
						this.fileList = list
						this.$emit('input', list)
					}
				})
			},
			// 从后端获取 阿里云 上传凭证token
			uploadToken(files) {
				this.$http.post('/robot/getToken').then((result) => {
					let qiNiuAddr = ''
					if (window._config.logoTitle == '一起抽盒鸭') {
						qiNiuAddr = "https://boxqn.hzkuqu.com/" //七牛云的图片外链地址 抽盒鸭
					} 
					else if (window._config.logoTitle == '乐芒抽盒机') {
						qiNiuAddr = "https://qn.hzkuqu.com/" //七牛云的图片外链地址  乐芒
					} else if (window._config.logoTitle == '欧气酱潮玩') {
						qiNiuAddr = 'https://oqmanage.hzhxyqchy.com/' //七牛云的图片外链地址  欧气酱
					} 
					else if (window._config.logoTitle == '平台4') {
						qiNiuAddr = 'https://upimg.hzhxyqchy.com/' //七牛云的图片外链地址  欧气酱
					}
					for (let i = 0; i < files.length; i++) {
						let fileItem = files[i]
						// 重命名要上传的文件
						const keyName = 'manage/' + new Date().getTime() + randomString(6)
						//Math.floor(Math.random() * 100)  //随机字符串
						const formData = new FormData()
						formData.append('file', fileItem)
						formData.append('token', result.data.data)
						formData.append('key', keyName)
						this.upFileQiNiu(formData, qiNiuAddr)
					}
				})
			},
			// 从后端获取 腾讯云 上传凭证token
			uploadToken2(files) {
				getQiNiuToken({}).then((result) => {
					const qiNiuAddr = "https://qn.hzkuqu.com/" ////七牛云的图片外链地址
					for (let i = 0; i < files.length; i++) {
						let fileItem = files[i]
						// 重命名要上传的文件
						const keyName = new Date().getTime() + randomString(6)
						const formData = new FormData()
						formData.append('file', fileItem)
						formData.append('token', result.data.data)
						formData.append('key', keyName)
						this.upFileQiNiu(formData, qiNiuAddr)
					}
				})
			},
			//上传文件到七牛云上
			upFileQiNiu(formData, qiNiuAddr) {
				// 获取到凭证之后再将文件上传到七牛云空间
				axios.post(this.domain, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					let imageUrl = qiNiuAddr + res.data.key
					if (this.fileNumber == 1) {
						this.fileList = [imageUrl]
						this.$emit('input', imageUrl)
					} else {
						let list = this.fileList
						list.push(imageUrl)
						this.fileList = list
						this.$emit('input', list)
					}
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.upload_file {
		.update_button {
			width: 100px;
			height: 100px;
			border-radius: 10px;
			border: 1px dashed #c0ccda;
			background-color: #fbfdff;
			position: relative;
			overflow: hidden;

			.icon_pos {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}

			input {
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				z-index: 99;
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		.update_file {
			width: 100px;
			height: 100px;
			border-radius: 10px;
			position: relative;
			overflow: hidden;

			.upload_img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 2;
			}

			.upload_pos {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 99;

				span {
					display: none;
				}
			}

			.upload_pos:hover {
				background: rgba(0, 0, 0, 0.5);

				.upload-preview {
					color: #fff;
					display: block;
				}

				.upload-delete {
					color: #fff;
					display: block;
				}
			}
		}
	}
</style>
